/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi,
} from "../../../utils/methods";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import Waiting from "../../Waiting";
import { toast } from "react-toastify";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
  InputImage,
  ButtonContainer,
  Button,
  EditorContainer,
  CustomRadioButton,
  RemoveImageV2
} from "../../CreateEvent/style";
import { Main } from "../../AdminTeamCreation/styles";
import StepsHeading from "../../CreateEvent/stepNames";
import {
  // TitleContainer,
  // CustomMenuItem,
  // FieldTitle,
  // ActivityDropdown,
  ToolTip
} from "../../AdminTeamCreation/styles";
import Editor from '../../CKEditor';
import HoverButton from '../../common/HoverButton';
import { ImageUrl, WellnessLevels } from "../../../utils/constants";
import { /*AdminEventsGoBackNewIcon,*/ UploadIcon, closeIcon, AdminEventsSubmitNewRocketIcon, Arrows, NextArrows } from "../../../utils/icons";
import { Border } from "../../Journeys/styles";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import { eventsTierPoints } from "../../../../mockData";
import { EditOndemandEventVideo } from "../../../redux/actions";
import CommonButton from "../../common/CommonButton/CommonButton";
import {TitleContainer, NewActivityDropdownCommon} from '../../common/CommonDropDown/style';
import { Dropdown } from "react-bootstrap";
import { CreateOnDemandFitnessVideo, EditOnDemandVideo, /*FetchCoaches,*/ GetCategoryCoaches } from '../../../redux/constants/apiConstants';
// import { BASE_URL } from "../../../utils/config";
class CreateOndemandMain extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      url: "",
      time: "",
      description: "",
      imageName: "",
      imgSrc: "",
      classTypeKey: "",
      classCategoryTypeValue: [],
      pointValue: "",
      coachId: null,
      flayerName: "",
      buttonstatus: false,
      step: 1,
      so: false,
      showBack:false,
      buttonClickScrollTop:false,
      selectedOndemandCategory: '',
      selectedOndemandCategoryValue:[],
      so2: false,
      levels:null,
      getCoachData:[],
      so3: false,
      coachName: '',
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      },
      fetchOndemandEventDetailsById,
      ondemandEventDetail,
      fetchOndemandCategory,
      isEditCategory
    } = this.props;
    const { flayerName } = this.state;
    fetchOndemandCategory();
    if(!isEditCategory){
      this.fetchCoachData([]);
    }
    else{
      this.fetchCoachData(ondemandEventDetail?.hra_category_id);
    }
    if(isEditCategory){
      const StringArray = pathname.split("/");
      if (StringArray[StringArray.length - 1] != "") {
        fetchOndemandEventDetailsById(StringArray[StringArray.length - 1]);
      } else {
        fetchOndemandEventDetailsById(StringArray[StringArray.length - 2]);
      }
      if (!ondemandEventDetail || _.isEmpty(ondemandEventDetail)) {
        this.props.history.push(`/events`);
      } else {
        this.setState({
          title: ondemandEventDetail.title,
          imgSrc: `${ImageUrl}/${ondemandEventDetail.thumbnail_image}`,
          url: ondemandEventDetail.video_url,
          classTypeKey: ondemandEventDetail.category_name,
          time: ondemandEventDetail.time,
          point_value: ondemandEventDetail.point_value,
          description: ondemandEventDetail.description,
          coachId: ondemandEventDetail?.coach_id,
          flayerName: flayerName,
          imageName: ondemandEventDetail.thumbnail_image,
          classCategoryTypeValue: ondemandEventDetail.hra_category_id,
          imageValueForEdit:ondemandEventDetail.thumbnail_image,
          pointValue:ondemandEventDetail.point_value
        });
        if(ondemandEventDetail.question_id){
          // this.getWBAQuestions(ondemandEventDetail.hra_category_id, ondemandEventDetail.question_id)
        }
        if(ondemandEventDetail.levels){
          const selectedLevel = WellnessLevels.find((wellnessLevel) => wellnessLevel.level === ondemandEventDetail.levels);
          this.setState({levels:selectedLevel})
        }
      }}
  }

  componentDidUpdate() {
    if(this.state.buttonClickScrollTop){
      window.scrollTo(0,0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }
    const { ondemandEventDetail, isEditCategory } = this.props;
    const { getCoachData, coachName, classCategoryTypeValue } = this.state;
    if( isEditCategory && ondemandEventDetail?.coach_id && getCoachData.length>0 && !coachName && (ondemandEventDetail.hra_category_id==classCategoryTypeValue)){
      const coachData = getCoachData?.find((coach) => coach?.id == ondemandEventDetail?.coach_id);
      if(!coachName){
        this.setState({coachName:coachData?.name})
      }
    }
  }

  fetchCoachData = async(selectescategories)=>{

    let apiUrl = GetCategoryCoaches;

    const payload = {categories:[...selectescategories]};

    console.log("payload",payload); 
    
    try{
      const res = await fetchApi(apiUrl, "POST", payload);
      if(res.message && res.code !== -273){
        toast.error(res.message);
      }else{
        this.setState({
          getCoachData:res.data
        })
      }

    }catch(error){
      toast.error(error)
    }
  }


  onChangeInput = (e) => {
    if(e.target.value.charAt(0) !== ' '){
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onChangeURL = (e) => {
    if(e.target.value.charAt(0) !== ' '){
      this.setState({
        url: e.target.value,
      });
    }
  };

  onChangeTime = (e) => {
    const numValidate = /^[0-9\b]+$/;
    if (e.target.value === "" || numValidate.test(e.target.value)) {
      this.setState({ time: e.target.value });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error("Please select correct file format");
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  // onSelectClass = (name, value, key) => {
  //   const {classCategoryTypeValue} = this.state;

  //   console.log("name",name);
  //   console.log("value",value);
  //   console.log("key",key);

  //   if(classCategoryTypeValue.includes(value)){
  //     let index = classCategoryTypeValue.findIndex((item) => item === value);
  //     if(index>-1){
  //       classCategoryTypeValue.splice(index, 1);
  //     }
  //   }
  //   else{
  //     if(classCategoryTypeValue.length <= 4)
  //     {
  //       classCategoryTypeValue.push(value);
  //     }
  //   }

  //   this.setState({
  //     [name]: value.toString(),
  //     selectedOndemandCategoryValue:[value],
  //     classTypeKey: key,
  //     coachId: null,
  //     coachName: '',
  //   }, () => {
  //     this.fetchCoachData(value);
  //   })
  // };

  onSelectClass = (name, value, key) => {
    const {classCategoryTypeValue} = this.state;
    const selectedCategories = [...classCategoryTypeValue]

    console.log("name",name);
    console.log("value",value);
    console.log("key",key);

    if(selectedCategories.includes(value)){
      let index = selectedCategories.findIndex((item) => item === value);
      if(index>-1){
        selectedCategories.splice(index, 1);
      }
    }
    else{
      if(selectedCategories.length <= 4)
      {
        selectedCategories.push(value);
      }
    }

    this.setState({
      [name]: selectedCategories,
      //   selectedOndemandCategoryValue:[value],
      classTypeKey: key,
      coachId: null,
      coachName: '',
    }, () => {
      this.fetchCoachData(this.state.classCategoryTypeValue);
    })
  };

  eventButtonStatusHover = () => {
    this.setState({
      showBack: true,
    });
  };


  eventButtonStatus = () => {
    this.setState({
      showBack: false,
    });
  };

  saveOndemandEvent = () => {
    // e.preventDefault();
    const {
      title,
      url,
      classCategoryTypeValue,
      time,
      description,
      pointValue,
      imgSrc,
      coachId,
      flayerName,
    } = this.state;
    // const { createOndemand } = this.props;
    this.setState({
      buttonstatus: true,
    });
    const OndemandEventDetails = {
      title: title,
      image: imgSrc,
      video_url: url,
      hra_category_id: classCategoryTypeValue,
      time: time,
      point_value: pointValue,
      description: description,
      coach_id: coachId,
      flyer: flayerName,
    };

    // createOndemand(OndemandEventDetails);
    this.PostOndemandEvent(OndemandEventDetails);
  };

  PostOndemandEvent = async(payload)=>{
    try{
      const res = await fetchApi(CreateOnDemandFitnessVideo, "POST",payload);
      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push({pathname:"/company/events",state:"updateData"})
      }
    }catch(error){
      toast.error(error);
    }
  }

  editOndemandEvent = () => {
    // e.preventDefault();
    const {
      title,
      url,
      classCategoryTypeValue,
      time,
      description,
      pointValue,
      imgSrc,
      coachId,
      flayerName,
      classTypeKey,
      imageValueForEdit,
      question,
      levels
    } = this.state;
    const { ondemandEventDetail } = this.props;
    this.setState({
      buttonstatus: true,
    });
    const OndemandEventDetails = {
      id: ondemandEventDetail.id,
      title: title,
      image: imgSrc,
      video_url: url,
      hra_category_id: classCategoryTypeValue,
      time: time,
      point_value: pointValue,
      description: description,
      coach_id: coachId,
      flyer: flayerName,
      imageUpdated:imageValueForEdit===ondemandEventDetail.thumbnail_image?0:1
    };
    OndemandEventDetails['question_id'] = question && question.id ? question.id : null;
    OndemandEventDetails['levels'] = levels?.level;
    // EditOndemandEventVideo(OndemandEventDetails, classTypeKey);
    this.editOndemandEventVideo(OndemandEventDetails, classTypeKey);
  };

  editOndemandEventVideo = async(payload, category)=>{
    try{
      const res = await fetchApi(EditOnDemandVideo, "PUT",payload);
      if(res.message){
        toast.error(res.message);
        this.setState({
          buttonstatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push({pathname:`/events/on-demand/${category}/${payload.id}`});
        this.setState({
          buttonstatus: false,
        });
      }
    }catch(error){
      toast.error(error);
      this.setState({
        buttonstatus: false,
      });
    }
  }

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString === "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop:true });
  };

  close = () =>(
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{maskType: "alpha",}}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  )

  redirect=()=>{
    // this.props.history.push(`/events`);
    this.props.history.goBack();
  }

  renderHeading = () => (
    <ChallengeHeading style={{color:"#005c87"}}>
      <ToolTip
        margin={"auto 15px auto 0"}
        activeValue={"transparent"}
        height="40px"
        width="55px"
      >
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={this.close()} 
          onClick={this.redirect}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </ToolTip>
      {this.props.isEditCategory?this.props.t("Edit On-Demand Event"):this.props.t("Create On-Demand Event")}</ChallengeHeading>)
  stepOneForm = () => {
    const {
      // title,
      // url,
      description,
      step,
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container  color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="ondemand"/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Event Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the Event")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={this.props.t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={this.state.title}
                    maxLength="100"
                    style={{background:"white"}}
                    pColor={"rgba(0, 92, 135, 0.30)"}
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>

                <StyledLabel color="#005c87">{this.props.t("Event image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.state.imgSrc}></img>
                        <div className="main-div" onClick={this.removePhoto}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">

                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1} 
                          style={{background:"unset",color:"#005c87"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">({this.props.t(".jpeg, .png")})</div>
                            
                          </div>
                          {/* <i className="addImg">{this.props.t("Upload Photo")}</i> */}
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept="image/jpeg, image/jpg, image/png"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}

                    {/* {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto} style={{backgroundColor:"#F4AAA9"}}>
                        {this.props.t("Remove Photo")}
                      </RemoveImage>
                    )} */}
                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>

                <InputContainer>
                  <StyledLabel color="#005c87">{this.props.t("About The Event")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor content={description} onChange={this.onChangeDescription} />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      [name]: name.value,
      eventPointKey: name.key,
      pointValue: name.value,
    });
  };

  onDropDownSelect = (key, item) => {
    this.setState({
      [key]: item
    });
  };

  onDropDownSelectCoach = (name, id, value) => {
    this.setState({
      [name]: id,
      coachName: value
    });
  };

  stepTwoForm = () => {
    const {
      time,
      classCategoryTypeValue,
      pointValue,
      step,
      url,
      getCoachData,
      coachId,
      coachName,
    } = this.state;
    const { ondemandCategory, isEditCategory } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="ondemand" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName" style={{ color: "#005c87" }}>
                  {this.props.t("How would you like to customize your event, and when do you plan to launch it?")}
                </div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>
                    {this.props.t("Select category tag")}
                    <span style={{color: "#005C87", fontFamily: "Rubik-Light", fontSize: "16px", fontStyle: "normal", fontWeight: "300",lineHeight: "20px"}}>&nbsp;
                      (min 1 & max 5)
                    </span>
                    {<span>*</span>}
                  </StyledLabel>
                  <div className="radioButtonClassCategories" >
                    {
                      ondemandCategory.map((category, index)=>(
                        <span key={category.id}>
                          <CustomRadioButton
                            onClick={() => {
                              this.onSelectClass(
                                "classCategoryTypeValue",
                                ondemandCategory[index].id,
                                ondemandCategory[index].name
                              );
                              this.setState({
                                selectedOndemandCategory: ondemandCategory[index].name
                              });
                            }}
                            background={"#85c0ea"}
                          >
                            {classCategoryTypeValue.includes(ondemandCategory[index].id) && <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>}
                            {/* { this.state.classCategoryTypeValue?.includes(ondemandCategory[index].id) && <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>} */}
                          </CustomRadioButton>
                          {category.name}
                        </span>
                      ))
                    }
                  </div>
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <CommonDropdown
                  tileContainerStyle={{ width: "100%",padding:"0px 0px 20px 0px" }}
                  dropdownStyle={{ top: "90px" }}
                  labelText={this.props.t("Event point value")}
                  isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={this.props.t("Select point value")}
                  title={
                    pointValue
                      && `${pointValue} Points`
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={eventsTierPoints}
                  onSelect={this.onSelectPoints}
                  active={pointValue}
                  onSelectParameter={["pointValue", "value", "key"]}
                  valueString={"Points"}
                  itemValue={true}
                  valueIndex={1}
                  labelMargin={"0px"}
                />
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px', display:"flex"}}/>

                {isEditCategory&&<TitleContainer tileContainerStyle={{padding:"20px 0px 20px 0px" }}>
                  <StyledLabel margin={"0px"}>{this.props.t("Level")}</StyledLabel>
                  <NewActivityDropdownCommon
                    onClick={() => this.setState({ so2: !this.state.so2 })}
                    onSelect={(index) =>
                      this.onDropDownSelect("levels", WellnessLevels[index])
                    }
                  >
                    <Dropdown.Toggle>
                      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div style={{color: !this.state["levels"]?.level && "rgba(0, 92, 135, 0.30)" }}>{this.state["levels"] && this.state["levels"].level && this.state["levels"].level || "Select Level"}</div>
                        <div>
                          <img
                            alt="image"
                            src={"/public/images/teamDownArrow.svg"}
                          />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {WellnessLevels.map((item, index) => (
                        <Dropdown.Item
                          eventKey={index}
                          key={index}
                          onSelect={() =>
                            this.onDropDownSelect("levels", item)
                          }
                          active={this.state["levels"] && this.state["levels"].id && this.state["levels"].id === item.id}
                        >
                          {item["level"]}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </NewActivityDropdownCommon>
                </TitleContainer>}
                {isEditCategory&&<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'30px 0px 0px 0px', display:"flex"}}/>}

                <InputContainer>
                  <StyledLabel style={{color:"#005c87"}}>{this.props.t("Event URL link")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={this.props.t("Enter class link")}
                    name="title"
                    onChange={this.onChangeURL}
                    value={url}
                    maxLength="100"
                    style={{background:"white"}}
                    pColor={"rgba(0, 92, 135, 0.30)"}
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>

                <InputContainer>
                  <StyledLabel style={{color:"#005c87"}}>
                    {this.props.t("Total Event Duration")}<span style={{color: "rgb(0, 92, 135, 0.70)"}}>&nbsp;(in Minutes)</span>{<span>*</span>}
                  </StyledLabel>
                  <StyledInputV2
                    placeholder={this.props.t("Enter event duration")}
                    name="title"
                    onChange={this.onChangeTime}
                    value={time}
                    maxLength="3"
                    style={{ background: "white" }}
                    pColor={"rgba(0, 92, 135, 0.30)"}
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
              
                {<TitleContainer tileContainerStyle={{padding:"20px 0px 20px 0px" }}>
                  <StyledLabel margin={"0px"}>{this.props.t("Event Host")}</StyledLabel>
                  <NewActivityDropdownCommon
                    onClick={() => this.setState({ so3: !this.state.so3 })}
                  >
                    <Dropdown.Toggle>
                      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div style={{color: !coachName&& "rgba(0, 92, 135, 0.30)" }}>{getCoachData?.length==0?"None":coachName?coachName:"Select Coach"}</div>
                        <div>
                          {getCoachData?.length>0&&<img
                            alt="image"
                            src={"/public/images/teamDownArrow.svg"}
                          />}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    {getCoachData?.length>0&&<Dropdown.Menu>
                      {getCoachData?.length>0 && getCoachData.map((item, index) => (
                        <Dropdown.Item
                          eventKey={index}
                          key={index}
                          onClick={() =>
                            this.onDropDownSelectCoach("coachId", item.id, item.name)
                          }
                          active={coachId === item.id}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>}
                  </NewActivityDropdownCommon>
                </TitleContainer>}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  render() {
    const {
      title,
      url,
      time,
      description,
      imgSrc,
      classCategoryTypeValue,
      pointValue,
      buttonstatus,
      step,

    } = this.state;
    const { ondemandCategory, isEditCategory } = this.props;
    if (isUndefined(ondemandCategory) || isNull(ondemandCategory)) {
      return <Waiting />;
    }
    return (
      <React.Fragment>
        {step === 1 ? this.stepOneForm() : this.stepTwoForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                disabled={!title || !imgSrc || !description}
                title={this.props.t("Next")}
                styles={{color:"#007AB1"}}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <Button
                onClick={!buttonstatus ? isEditCategory? () => this.editOndemandEvent() :  () => this.saveOndemandEvent() : null}
                // color="#005c87"
                marginAuto="1"
                disabled={
                  (pointValue === null || pointValue === undefined || pointValue === '') || 
                  // !pointValue ||
                  !time ||
                  classCategoryTypeValue.length==0 ||
                  !url ||
                  buttonstatus
                }
                isOnDemandEvent={true}
              >
                <span style={{display: "flex", flexDirection: "row", gap: "12px", alignItems: "center"}}>
                  <span>{this.props.t("Submit")}</span>
                  <span>{AdminEventsSubmitNewRocketIcon()}</span>
                </span>
              </Button>
            </div>
          </ButtonContainer>
        )}
      </React.Fragment>
    );
  }
}

CreateOndemandMain.propTypes = {
  createOndemand: PropTypes.func.isRequired,
  fetchOndemandCategory: PropTypes.func,
  ondemandCategory: PropTypes.array,
  history: PropTypes.object.isRequired,
  t: PropTypes.func,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  ondemandEventDetail: PropTypes.object,
  isEditCategory: PropTypes.object,
  EditOndemandEventVideo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  ondemandEventDetail: state.events.ondemandEventDetail,
  ondemandCategory: state.events.ondemandCategory,
});

const mapDispatchToProps = (dispatch) => ({
  EditOndemandEventVideo: (data, category) =>dispatch(EditOndemandEventVideo(data, category)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CreateOndemandMain));